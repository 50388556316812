import { createSlice } from "@reduxjs/toolkit"
import { keyBy, merge, cloneDeep, mergeWith, isArray } from "lodash"
import { OrderedObject, mapObject } from "@lib/utils"

const initialState = {
  mapboxSources: {},
  mapboxLayers: {},
  mapboxLayersOrder: [],
  infoLayers: {},
  mapboxProperties: {
    mapStyle: "satellite",
    projection: "globe",
    tooltipInfo: null,
  },
}

const mapdataSlice = createSlice({
  name: "mapdata",
  initialState,
  reducers: {
    mergeMapboxProperty: (state, { payload, type }) => {
      state.mapboxProperties = merge(
        {},
        state.mapboxProperties,
        cloneDeep(payload)
      )

      return state
    },
    setInfoLayer: (state, { payload, type }) => {
      let { key, config } = payload

      const arrayMerger = (obj, src) => {
        if (isArray(obj)) {
          return Array.from(new Set(obj.concat(src)))
        }
      }
      
      state.infoLayers[key] = mergeWith(state.infoLayers[key], config, arrayMerger)

      return state
    },
    setInfoLayers: (state, { payload, type }) => {
      // key-value pairs, or a list of infoLayer configurations
      let { configs } = payload

      if (Array.isArray(configs)) {
        configs = keyBy(configs, "id")
      }

      state.infoLayers = merge({}, state.infoLayers, configs)

      return state
    },
    removeInfoLayers: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      state.infoLayers = mapObject(state.infoLayers, (k, v) =>
        !keys.includes(k) ? [k, v] : false
      )

      return state
    },
    setMapboxSources: (state, { payload, type }) => {
      // key-value pairs, or a list of mapboxSource configurations
      let { configs } = payload

      if (Array.isArray(configs)) {
        configs = keyBy(configs, "id")
      }

      state.mapboxSources = merge({}, state.mapboxSources, configs)

      return state
    },
    removeMapboxSources: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      // For each mapboxSoruce, if its k key is in the list of keys to remove, then it is removed.
      // Otherwise, it is kept as-is
      state.mapboxSources = mapObject(state.mapboxSources, (k, v) =>
        !keys.includes(k) ? [k, v] : false
      )

      return state
    },
    setMapboxLayers: (state, { payload, type }) => {
      // key-value pairs, or a list of mapboxLayer configurations
      let { configs } = payload

      const newLayers = Array.isArray(configs)
        ? OrderedObject.pickFromArray(configs, "id")
        : OrderedObject.createFromObject(configs)
      const stateLayers = OrderedObject.createFromObject(
        state.mapboxLayers,
        state.mapboxLayersOrder
      )

      const mergedLayers = stateLayers.update(newLayers)
      state.mapboxLayers = mergedLayers.object()
      state.mapboxLayersOrder = mergedLayers.keys()
      return state
    },
    setMapboxFilterProp: (state, { payload, type }) => {
      let { key, filter } = payload

      state.mapboxLayers[key].filter = filter
      return state
    },
    setMapboxLayer: (state, { payload, type }) => {
      let { key, config } = payload

      state.mapboxLayers[key] = merge(state.mapboxLayers[key], config)
      return state
    },
    removeMapboxLayers: (state, { payload, type }) => {
      // list of keys
      const { keys } = payload

      state.mapboxLayers = mapObject(state.mapboxLayers, (k, v) =>
        !keys.includes(k) ? [k, v] : false
      )

      return state
    },
  },
})

export const {
  mergeMapboxProperty,
  setInfoLayers,
  setInfoLayer,
  removeInfoLayers,
  setMapboxSources,
  removeMapboxSources,
  setMapboxLayers,
  setMapboxLayer,
  setMapboxFilterProp,
  removeMapboxLayers,
} = mapdataSlice.actions

export default mapdataSlice.reducer
